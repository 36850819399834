import type { GetStaticProps } from 'next'
import { useRouter } from 'next/router'
import type { FC } from 'react'

// import Layout from '@components/common/Layout'
// import type { LayoutProps } from '@components/common/Layout/Layout'
import PublicNavbar from '@components/common/PublicNavbar'
import type { PageProps } from '@motif/shared/pages/generate-static-props'
import { generateStaticProps } from '@motif/shared/pages/generate-static-props'
import { getActivePublicationTimestamp } from '@motif/shared/pages/queries/publications'
import Slug from '@motif/shared/pages/slug'
import type { TemplateProps } from '@motif/shared/pages/template'
import { getTemplate } from '@motif/shared/pages/template'
import { getSlugComponents } from '@motif/shared/utils/routes'

// Important: the shimmed editor should not be SSR'ed. The reason
// is that the server cannot access Replicache, and so will not
// be able to wrap the component in its proper context (provided by
// Layout), and this will generate a runtime error on the server side
// and prevent the page from rendering.
// const ShimmedEditor = dynamic(() => import('@components/common/EditorShim'), {
//   ssr: false,
// })

const AnimatedCaret = () => {
  return (
    <span className="animate-caret mb-[-8px] ml-[2px] inline-block h-[48px] w-[5px] rounded-full bg-[#15E3FF] sm:mb-[-14px] sm:ml-[3px] sm:h-[64px] sm:w-[7px]" />
  )
}

const Home: FC<PageProps> & {
  isPublicHome: boolean
  // Layout: FC
  getTemplate: (
    page: React.ReactNode,
    templateProps: TemplateProps
  ) => JSX.Element
} = (props) => {
  const router = useRouter()
  const slugComponents = getSlugComponents(router)

  if (!props.mdxCode || props.error) {
    return (
      <div className="flex flex-col">
        <div className="fixed z-20 w-full">
          <PublicNavbar />
        </div>
        <h1 className="mt-48 p-8 text-center text-5xl font-black text-slate-900 sm:text-6xl">
          When content meets code,
          <br className="hidden pr-1 sm:block" /> magic happens
          <AnimatedCaret />
        </h1>
      </div>
    )
  }

  return (
    <div className="standalone">
      <Slug
        {...props}
        includeBranding={false}
        slugComponents={slugComponents}
      />
      {/* ShimmedEditor={ShimmedEditor} /> */}
    </div>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const projectId = process.env.MOTIF_WEBSITE_PROJECT_ID || ''
  const timestamp = await getActivePublicationTimestamp(projectId)
  const pageProps = await generateStaticProps(
    [''],
    projectId,
    timestamp,
    false,
    false,
    'motif.land',
    true
    // ShimmedEditor
  )

  return {
    ...pageProps,
    revalidate: 10,
  }
}

Home.isPublicHome = true
Home.getTemplate = getTemplate

export default Home
